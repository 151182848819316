
























































































































































import {
	Component,
	Vue,
	Prop,
	Emit,
	Watch,
} from 'vue-property-decorator';
import { Form } from '@/model/util/Form';
import { EValidate } from '@/model/util/ValidationType';
import { validator } from '@/utils/Validation';
import Util from '@/utils/Util';
import { IItemSelect } from '@/model/util/IItemSelect';
import { ICamping } from '@/model/main/ICamping';
import { Icon } from '@/model/util/Icon';

@Component({
	name: 'formBase',
})
export default class formBase extends Vue {
	@Prop({
		type: Array as () => Array<Form>,
		required: false,
		default: () => Array<Form>(),
	})
	readonly forms!: Array<Form>;
	@Prop({
		type: Boolean,
		required: true,
	})
	readonly waiting!: boolean;
	@Prop({
		type: String,
		required: true,
	})
	readonly routing!: string;
	@Prop({
		type: Boolean,
		required: true,
	})
	readonly reset!: boolean;
	@Prop({
		type: String,
		required: false,
		default: undefined,
	})
	readonly backText!: string;
	@Prop({
		type: String,
		required: false,
		default: 'Guardar',
	})
	readonly confirmText!: string;

	alerta(){
		Util.showMessage('OK', Icon.SUCCESS)
	}
	private form: any = undefined;
	private date: any = undefined;
	private dates = this.forms.reduce(
		(acc: any, current: Form, index: number) => {
			let returns = acc;
			if (current.type === 'date') {
				acc[index] = Object.keys(acc).length;
			}
			return returns;
		},
		{}
	);
	public mounted() {
		this.form = this.$refs.form;
		this.date = this.$refs.menu;
		this.cloneForm();
		console.log(this.dates);
	}
	public fields: Array<Form> = [];
	public valid = false;
	get getRules() {
		return (rules: Array<EValidate>) =>
			validator.getRules(rules);
	}
	@Watch('forms')
	public cloneForm() {
		this.fields = JSON.parse(
			JSON.stringify(this.forms)
		) as Array<Form>;
	}

	save(date: any, index: number) {
		console.log('hola');

		this.forms[index].model.extra = date;
		this.forms[index].model.value = date;
		const datePos: number = this.dates[index];
		const menu: any = this.$refs.menu;
		menu[datePos].save();
		this.forms[index].model.value = date;
		// this.$refs.menu.save(date);
	}
	@Watch('reset')
	public async resetChanged(newValue: boolean) {
		if (newValue === true) {
			await this.form.reset();
			this.returnResetStatus();
		}
	}
	@Emit('onReset')
	public returnResetStatus() {
		return false;
	}
	@Emit('onGetData')
	public async getData() {
		await this.form.validate();
		if (this.valid === false) {
			return {};
		}
		return this.fields.reduce(
			(acumulador, item: Form, index: number) => {
				const value = JSON.parse(
					JSON.stringify(item.model.value)
				);
				Object.setPrototypeOf(
					value,
					Object.getPrototypeOf(item.model.value)
				);
				console.log(value);
				return Object.assign(acumulador, {
					[item.model.name]: value,
				});
			},
			{}
		);
	}
	@Emit('onBack')
	public redirectTo() {
		return true;
	}
	protected changeFile(e: File, index: number) {
		this.fields[index].model.value = e;
		console.log(e, arguments);
	}
	protected onChangeCamping(payload : any): void {
		console.log(payload);
		console.log("camping type: ", typeof(this.fields[2].model.extra));
		console.log("campings: ", this.fields[2].model.extra);
		if (payload.length === 0){
			for (let item of this.fields[2].model.extra as Array<IItemSelect>){
				item.disabled = false;
			}
		}
		else {
			for (let item of this.fields[2].model.extra as Array<IItemSelect>){
				if (payload[0] !== item.value){
					item.disabled = true;
				}
			}
		}
	}
	protected onChangeCampingEvent(camps: string): void {
		console.log(camps);
		console.log("camping type: ", typeof(this.fields[2].model.extra));
		console.log("campings: ", this.fields[2].model.extra);
		if (camps.length === 0) {
			for (let item of this.fields[2].model.extra as Array<IItemSelect>){
				item.disabled = false;
			}
		}
		else if (camps.length === 1) {
			let script_type = camps[0].split('@@')[1];
			for (let item of this.fields[2].model.extra as Array<IItemSelect>){
				let item_value = item.value as string;
				if (script_type !== item_value.split('@@')[1]){
					item.disabled = true;
				}
			}
		}
	}
}
